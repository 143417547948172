import React, { Component } from 'react';
import axios from 'axios';
import { CookiesProvider, withCookies } from 'react-cookie';

import './videos-dashboard.scss';
import Constants from '../components/constants';

let trashImg = require('../images/delete.png');
class VideosDashboardPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            pass: '',

            isLogged: false,
            loginError: undefined,

            showModal: false,
            modalQuestion: '',
            modalFunction: () => {},
            numberOfPages: 1,
            currentPage: 0,

            videos: [],
            selectedVideo: undefined,
            currentMessage: ''
        };

        this.tryLogin = this.tryLogin.bind(this);
        this.logout = this.logout.bind(this);
        this._getAuthHeader = this._getAuthHeader.bind(this);

        this._getVideos = this._getVideos.bind(this);
        this.goNextPage = this.goNextPage.bind(this);
        this.goPrevPage = this.goPrevPage.bind(this);

        this.onMakePublic = this.onMakePublic.bind(this);
        this.sendSwitchPublicRequest = this.sendSwitchPublicRequest.bind(this);
        this.sendDeleteRequest = this.sendDeleteRequest.bind(this);
    }

    componentDidMount() {
        const { cookies } = this.props;

        let credentials = cookies.get('credentials');
        if (credentials == undefined) return;

        let { name, pass } = credentials;

        if (name && pass) {
            console.log('Trying to login');
            this.setState({ name: name, pass: pass }, () => {
                let header = this._getAuthHeader();
                axios
                    .get(Constants.SERVER_ADDRESS + '/login', { headers: header })
                    .then(res => {
                        this.setState({ isLogged: true });
                        this._getVideos();
                    })
                    .catch(err => {
                        this.logout();
                    });
            });
        } else {
            console.log('No credentials. Please login!');
        }
    }

    _getVideos() {
        axios
            .get(Constants.SERVER_ADDRESS + '/admin-videos?page=' + this.state.currentPage, {
                headers: this._getAuthHeader()
            })
            .then(res =>
                this.setState({
                    videos: res.data.videos,
                    numberOfPages: res.data.numberOfPages
                })
            )
            .catch(err => {
                this.logout();
            });
    }

    _getAuthHeader() {
        return { authorization: 'Basic ' + btoa(this.state.name + ':' + this.state.pass) };
    }

    goPrevPage() {
        if (this.state.currentPage == 0) return;
        this.setState({ currentPage: this.state.currentPage - 1 }, () => this._getVideos());
    }

    goNextPage() {
        if (this.state.currentPage == this.state.numberOfPages - 1) return;
        this.setState({ currentPage: this.state.currentPage + 1 }, () => this._getVideos());
    }

    onMakePublic(video) {
        console.log('Making video is public ' + !video.public + '...');
        this.setState({
            showModal: true,
            modalQuestion:
                'Are you sure you want to make ' +
                video.childNickname +
                "'s video " +
                (video.public ? ' hidden' : ' public') +
                '?',
            modalFunction: this.sendSwitchPublicRequest,
            selectedVideo: video
        });
    }

    sendSwitchPublicRequest() {
        let video = this.state.selectedVideo;
        console.log('Setting ' + video.id + ' to ' + video.public);
        axios
            .post(
                Constants.SERVER_ADDRESS + '/video/' + video.id + '/public/' + !video.public,
                {},
                {
                    headers: this._getAuthHeader()
                }
            )
            .then(res => {
                this.setState({
                    showModal: false,
                    currentMessage:
                        'Video of ' + video.childNickname + ' is now ' + (!video.public ? 'public' : 'hidden') + '!'
                });
                this._getVideos();
            })
            .catch(err => {
                if (err.response.status === '401') {
                    this.logout();
                }
            });
    }

    sendDeleteRequest() {
        let video = this.state.selectedVideo;
        axios
            .delete(Constants.SERVER_ADDRESS + '/video/' + video.id, {
                headers: this._getAuthHeader()
            })
            .then(res => {
                this.setState({
                    showModal: false,
                    currentMessage: 'Video of ' + video.childNickname + ' is now deleted!'
                });
                this._getVideos();
            })
            .catch(err => {
                if (err.response.status === '401') {
                    this.logout();
                }
            });
    }

    tryLogin(event, cookies) {
        event.preventDefault();

        let header = this._getAuthHeader();

        axios
            .get(Constants.SERVER_ADDRESS + '/login', { headers: header })
            .then(res => {
                this.setState({ isLogged: true });
                cookies.set('credentials', { name: this.state.name, pass: this.state.pass });
                this._getVideos();
            })
            .catch(err => {
                this.setState({ loginError: 'Authorization failed!' });
            });
    }

    logout() {
        const { cookies } = this.props;
        cookies.remove('credentials');
        this.setState({ name: '', pass: '', isLogged: false });
    }

    render() {
        const { cookies } = this.props;

        return (
            <CookiesProvider>
                <div className="videos-dashboard__wrapper">
                    <h1>Videos</h1>
                    <p className="info"> {this.state.currentMessage}</p>
                    <table>
                        <tbody>
                            <tr>
                                <th>Nickname</th>
                                <th>Age</th>
                                <th>Country</th>
                                <th>Tutors Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Category</th>
                                <th>Question</th>
                                <th>Thumbnail</th>
                                <th>Video</th>
                                <th>Is Public</th>
                            </tr>
                            {this.state.videos.map(video => {
                                return (
                                    <tr key={video.id}>
                                        <td>{video.childNickname}</td>
                                        <td>{video.age}</td>
                                        <td>{video.country}</td>
                                        <td>{video.parents}</td>
                                        <td>{video.email}</td>
                                        <td>{video.phone}</td>
                                        <td>{video.category}</td>
                                        <td>{video.question}</td>
                                        <td>
                                            <img className="thumbnail" src={video.thumbnail} />
                                        </td>
                                        <td>
                                            <a href={video.videoUrl} target="_blank" rel="noopener noreferrer">
                                                See Video
                                            </a>
                                        </td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={video.public}
                                                onChange={() => {
                                                    this.onMakePublic(video);
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <button
                                                className="icon"
                                                onClick={() => {
                                                    this.setState({
                                                        showModal: true,
                                                        modalQuestion: `Are you sure you want to PERMANENTLY DELETE ${video.childNickname} 's video?`,
                                                        modalFunction: this.sendDeleteRequest,
                                                        selectedVideo: video
                                                    });
                                                }}
                                            >
                                                <img src={trashImg} />
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    <div className="pagination">
                        <button onClick={this.goPrevPage}> &lt; </button>
                        {this.state.currentPage + 1} / {this.state.numberOfPages}
                        <button onClick={this.goNextPage}> &gt; </button>
                    </div>

                    {this.state.showModal && (
                        <div className="are-you-sure__wrapper">
                            <div className="are-you-sure">
                                <p> {this.state.modalQuestion}</p>
                                <button className="danger" onClick={() => this.state.modalFunction()}>
                                    Yes
                                </button>
                                <button
                                    onClick={() => {
                                        this.setState({ showModal: false });
                                    }}
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    )}

                    {!this.state.isLogged && (
                        <div className="login__wrapper">
                            <div className="login">
                                <p> Please Login</p>
                                <p style={{ color: 'red' }}>{this.state.loginError}</p>
                                <form onSubmit={event => this.tryLogin(event, cookies)}>
                                    <input
                                        type="text"
                                        placeholder="Username"
                                        onChange={event => {
                                            this.setState({ name: event.target.value });
                                        }}
                                        value={this.state.name}
                                    />
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        onChange={event => {
                                            this.setState({ pass: event.target.value });
                                        }}
                                        value={this.state.pass}
                                    />
                                    <button> Login </button>
                                </form>
                            </div>
                        </div>
                    )}
                </div>
            </CookiesProvider>
        );
    }
}

export default withCookies(VideosDashboardPage);
